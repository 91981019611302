.whatsapp-icon-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    margin-bottom: 45px;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out; // Transición suave para la visibilidad

    &.hidden {
        opacity: 0;
        visibility: hidden; // Oculta el contenedor suavemente
    }

    // Mostrar el texto solo cuando se pasa el mouse sobre el contenedor
    &:hover .whatsapp-text {
        opacity: 1; // Hacer visible el texto cuando se hace hover
        transform: translateX(10px); // Desplazar el texto un poco hacia la derecha
    }
}

.whatsapp-content {
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 3px 12px;
    transition: transform 0.3s ease-in-out; // Suavizar la animación al hacer hover
}

.whatsapp-text {
    color: white;
    font-size: 14px;
    font-weight: bold;
    background-color: black;
    padding: 8px 14px;
    border-radius: 18px;
    margin-right: 15px; // Espacio entre el texto y el ícono
    display: inline-block;
    opacity: 0; // Mantener el texto oculto por defecto
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; // Suavizar la aparición del texto
}

.whatsapp-icon {
    background-color: #25D366;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
        transform: scale(1.3);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    }
}

// Animación de entrada con rebote
.animate-fade-in {
    opacity: 0;
    animation: fadeIn 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.8) translateY(20px);
    }
    50% {
        opacity: 1;
        transform: scale(1.1) translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}
